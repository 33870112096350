import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import LoadingButton from '@mui/lab/LoadingButton';

import { onError } from '../../lib/errorLib'
import "./addSeries.css";

export default function AddSeries() {

  // const file = useRef(null);
  const history = useHistory();
  const [seriesName, setSeriesName] = useState("");
  const [seriesDesc, setSeriesDesc] = useState("");
  const [seriesActive, setSeriesActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  function validateForm() {
    return (seriesName.length > 0 && seriesDesc.length > 0 && seriesActive.length > 0);
  }
  async function createSeries(newSeries) {
    // console.log("call createSeries with: ", newSeries);
    return API.post("clientnamebackend", "/createseries", {
      body: {content: newSeries}
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await createSeries({ title: seriesName, desc: seriesDesc, status: seriesActive });
      history.push("/sersea");
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }

    setIsLoading(true);
  }

  return (
    <div className="addSeries">
      <div className="addSeriesContainer">
        <h1 className="addSeriesTitle">Add New Series</h1>
        <form className="addSeriesForm" onSubmit={handleSubmit}>
          <div className="addSeriesItem">
            <label>Series Name</label>
            <input type="text" placeholder="My new Series" value={seriesName} onChange={(e) => setSeriesName(e.target.value)}/>
          </div>

          <div className="addSeriesItem">
            <label>Description</label>
            <textarea
                  placeholder={"Description of the newly added series"}
                  value={seriesDesc}
                  onChange={(e) => setSeriesDesc(e.target.value)}
                  rows="3" cols="100">
            </textarea>
          </div>

          <div className="addSeriesItem">
            <label>Active</label>
            <select className="addSeriesSelect" name="active" id="active" value={seriesActive} onChange={(e) => setSeriesActive(e.target.value)} >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {/* <button className="addSeriesButton">Create</button> */}
          <LoadingButton 
              type="submit"
              className="addSeriesButton" 
              fullWidth 
              variant="contained" 
              sx={{ mt: 3, mb: 2 }}  
              disabled={!validateForm()}
              loading={isLoading}
              loadingIndicator="Adding Series..."
            >
              Add
            </LoadingButton>
        </form>
      </div>
    </div>
  );
}
