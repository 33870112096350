import "./chart.css";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Chart({ title, data, dataKey, grid }) {

  let clrs = ['#f2ba89', '#683606', '#fb3b3b', '#7e1717'];
  let lines = dataKey.map((key, index) => {
    return <Line key={index} type="monotone" dataKey={key} stroke={clrs[index]}  strokeWidth={2} />
  });
  return (
    <div className="chart">
      <h3 className="chartTitle">{title}</h3>
      <ResponsiveContainer width="100%" aspect={3 / 1}>
        <LineChart data={data}>
          <XAxis dataKey="name" stroke="#5550bd" />
          {/* <Line type="monotone" dataKey={dataKey} stroke="#5550bd" /> */}
          {lines}
          <Tooltip />
          {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
