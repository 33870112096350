import React from 'react';
import { Link } from "react-router-dom";
import "./sidebar.css";
import config from '../../config';
import logo from "../../images/logo.png";

import LineStyleOutlinedIcon from '@mui/icons-material/LineStyleOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import LocalMoviesOutlinedIcon from '@mui/icons-material/LocalMoviesOutlined';
import MovieFilterOutlinedIcon from '@mui/icons-material/MovieFilterOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
// import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
// import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
// import VideoSettingsOutlined from '@mui/icons-material/VideoSettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useHistory } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import { Auth } from "aws-amplify";

const drawerWidth = 240;


export default function Sidebar(props) {

  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {

    await Auth.signOut();

    userHasAuthenticated(false);
    history.push("/signin");
  }
  // #TODO - remove hard-coded logo
  const drawer = (
    <div>
      <Toolbar>
        <img className="sidebarLogo" src={ logo } alt="images-logo" />
      </Toolbar>
      <Divider />
      <List>

        <ListItem button key={"Home"}>
          <h3 className="sidebarTitle">Home</h3>
        </ListItem>
        <Link to="/home" className="link">
          <ListItem button key={"Link1"}>
            <ListItemIcon> <LineStyleOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </Link>

        <Divider />
        <ListItem button key={"Channel Management"}>
          <h3 className="sidebarTitle">Channel Management</h3>
        </ListItem>
        <Link to="/users" className="link">
          <ListItem button key={"Users"}>
            <ListItemIcon> <PermIdentityOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Users"} />
          </ListItem>
        </Link>
        <Link to="/settings" className="link">
          <ListItem button key={"Settings"}>
            <ListItemIcon> <SettingsOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItem>
        </Link>

        <Divider />
        <ListItem button key={"All Media"}>
          <h3 className="sidebarTitle">All Media</h3>
        </ListItem>
        <Link to="/allvideos" className="link">
          <ListItem button key={"allvideos"}>
            <ListItemIcon> <VideoLibraryOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"All Videos"} />
          </ListItem>
        </Link>
        <Link to="/movies" className="link">
          <ListItem button key={"movies"}>
            <ListItemIcon> <LocalMoviesOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Movies"} />
          </ListItem>
        </Link>
        <Link to="/series" className="link">
          <ListItem button key={"series"}>
            <ListItemIcon> <MovieFilterOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Series Episodes"} />
          </ListItem>
        </Link>
        <Link to="/shortfilms" className="link">
          <ListItem button key={"shortfilms"}>
            <ListItemIcon> <SlideshowOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Short Films"} />
          </ListItem>
        </Link>
        <Link to="/unassorted" className="link">
          <ListItem button key={"unassorted"}>
            <ListItemIcon> <AddToQueueOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"UnAssorted"} />
          </ListItem>
        </Link>
        <Link to="/sersea" className="link">
          <ListItem button key={"sersea"}>
            <ListItemIcon> <DvrOutlinedIcon /> </ListItemIcon>
            <ListItemText primary={"Series/Season"} />
          </ListItem>
        </Link>

        <Divider />
        <Link to="/logout" className="link">
          <ListItem button key={"logout"} onClick={handleLogout} >
            <ListItemIcon> <LogoutIcon /> </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </Link>
        <Divider />
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: {xs: '#eb9546'},
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
           {config.client.CMS_CLIENT_NAME} CMS
          </Typography>
        </Toolbar>
      </AppBar>


      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="Sidebar navigation"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
