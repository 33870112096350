const config = {
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
    // BUCKET_VIDEOUPLOAD: process.env.REACT_APP_BKT_VU,
    AWS_KEY: process.env.REACT_APP_AWS_KEY,
    AWS_SECRET: process.env.REACT_APP_AWS_SECRET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  dropbox: {
    DROPBOX_CLIENT_ID: process.env.REACT_APP_DROPBOX_CLIENT_ID,
    DROPBOX_CLIENT_SECRET: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
    DROPBOX_REFRESH_TOKEN: process.env.REACT_APP_DROPBOX_REFRESH_TOKEN,
    DROPBOX_FOLDER: process.env.REACT_APP_DROPBOX_FOLDER,
  },
  bridTV: {
    CMS_BRIDTV_PARTNER_ID: process.env.REACT_APP_BRIDTV_PARTNER_ID,
  },
  client: {
    CMS_CLIENT_NAME: process.env.REACT_APP_CLIENT_NAME,
    CMS_CLIENT_EMAIL: process.env.REACT_APP_CLIENT_EMAIL,
    CMS_CLIENT_WEBSITE: process.env.REACT_APP_CLIENT_WEBSITE,
  },
};

export default config;