
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


import './topTen.css';


export default function TopTen({title, sortedData, dataKey}) {
    return (
      <div className="topten">
        <h3 className="toptenTitle">{title}</h3>
        <div className="toptenTable">
          <ResponsiveContainer width="100%"  aspect={3 / 1}>
            <BarChart
              data={sortedData}
              layout={"vertical"}
              margin={{
                top: 5,
                right: 5,
                left: 100,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#f2ba89" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#f2ba89" stopOpacity={0}/>
                </linearGradient>
              </defs>              
              size={"small"}
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis hide type='number'/>
              <YAxis type="category" dataKey="name" />
              <Tooltip />
              {/* <Legend /> */}
              {/* <Bar dataKey={dataKey} fill="#8884d8" /> */}
              <Bar type="monotone" dataKey={dataKey}  stroke="#8b4402" fillOpacity={1} fill="url(#colorUv)"/>
            </BarChart>
          </ResponsiveContainer>
      </div>
    </div>
    );
}