import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, LabelList } from 'recharts';

import "./customPieChart.css";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function CustomPieChart({title, data}) {
  return (
    <div className="chartPie">
      <h3 className="chartPieTitle">{title}</h3>
      <ResponsiveContainer width="100%" aspect={1.8 / 1}>
        <PieChart >
          <Pie
            data={data}
            cx={"50%"}
            cy={"50%"}
            innerRadius={50}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <LabelList dataKey="name" position="inside" />
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
