import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

import "./customAreaChartNoBshadow.css";

export default function CustomAreaChartNoBshadow({ data, dataKeyX, dataKeyY }) {
  return (
    <ResponsiveContainer width="100%" aspect={4 / 1}>
      <AreaChart 
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0, }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#f2ba89" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#f2ba89" stopOpacity={0}/>
          </linearGradient>
          {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient> */}
        </defs>
        <XAxis dataKey= {dataKeyX || "name"} angle={-90} textAnchor="end" />
        {/* <YAxis /> */}
        <Tooltip />
        <Area type="monotone" dataKey={dataKeyY} stroke="#8b4402" fillOpacity={1} fill="url(#colorUv)"/>
      </AreaChart>
    </ResponsiveContainer>
  );
}