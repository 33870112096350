import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";

import { Link } from "react-router-dom";
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import "./user.css";

export default function User() {

  const history = useHistory();

  let { userId } = useParams();

  const { isAuthenticated } = useAppContext();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  function loadUser() {
    // console.log("🚀 ~ file: User.jsx ~ line 38 ~ loadUser ~ loadUser with", userId)
    return API.get("clientnamebackend", "/getusers/" + userId);
  }
  
  async function updateUser(newUser) {
    // console.log("call updateUser with: ", newUser);
    return API.put("clientnamebackend", "/updateuser/"+newUser.userName, {
      body: {content: newUser}
    });
  }
  
  const handleUserSave = async (event) => {
    event.preventDefault();
    // console.log("🚀 ~ file: User.jsx ~ line 53 ~ handleUserSave ~ handleUserSave with", data)
    try {
      await updateUser(data);
      history.push("/users");
      setIsLoading(true);
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
    setIsLoading(true);
  };


  useEffect(() => {
    // console.log('useEffect USER called')
    async function onLoad() {
        if (!isAuthenticated) {
            history.push("/signin");
            return;
        }

        try {
            const userData = await loadUser();
            // console.log('Recvd userData = ', userData)
            setData(userData);
        } catch (e) {
            onError(e);
        }

        // setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, userId]);

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Edit User</h1>
        <Link to="/newUser">
          <button className="userAddButton">New Site User</button>
        </Link>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <div className="userShowTopTitle">
              <span className="userShowUsername">{data.userDisplayName}</span>
              <span className="userShowUserTitle">{data.userPlan}</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Account Details</span>
            <div className="userShowInfo">
              <PermIdentityOutlinedIcon className="userShowIcon" />
              <span className="userShowInfoTitle">{data.userName}</span>
            </div>
            <div className="userShowInfo">
              <CalendarTodayOutlinedIcon className="userShowIcon" />
              <span className="userShowInfoTitle">
                Account created on {data.userCreatedAt && (new Date(data.userCreatedAt)).toISOString().slice(0, 10).replace("T", " ")}
              </span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroidOutlinedIcon className="userShowIcon" />
              <span className="userShowInfoTitle">{data.userMobile}</span>
            </div>
            <div className="userShowInfo">
              <MailOutlineOutlinedIcon className="userShowIcon" />
              <span className="userShowInfoTitle">{data.userEmail}</span>
            </div>
            <div className="userShowInfo">
              <LocationSearchingOutlinedIcon className="userShowIcon" />
              <span className="userShowInfoTitle">{data.userCity}</span>
            </div>
            <div className="userShowInfo">
              <SupervisedUserCircleOutlinedIcon className="userShowIcon" />
              <span className="userShowInfoTitle">{data.userStatus ? 'Active' : 'Inactive' }</span>
            </div>
          </div>
        </div>
        <div className="userUpdate">
          <span className="userUpdateTitle">Edit</span>
          <form className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Username</label>
                <input
                  type="text"
                  readOnly
                  placeholder="userId"
                  value={data.userName}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={data.userDisplayName}
                  onChange={(e) => setData((prev) => ({ ...prev, userDisplayName: e.target.value }))}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Email</label>
                <input
                  type="text"
                  readOnly
                  placeholder="Email"
                  value={data.userEmail}
                  onChange={(e) => setData((prev) => ({ ...prev, userEmail: e.target.value }))}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="+1 123 456 67"
                  value={data.userMobile}
                  onChange={(e) => setData((prev) => ({ ...prev, userMobile: e.target.value }))}
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="New York | USA"
                  value={data.userCity}
                  onChange={(e) => setData((prev) => ({ ...prev, userCity: e.target.value }))}
                  className="userUpdateInput"
                />
              </div>
            </div>
            <div className="userUpdateRight">
              <button className="userUpdateButton" onClick={handleUserSave} >Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
