import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import { Auth } from "aws-amplify";
import { AppContext } from "./lib/contextLib";
import { onError } from "./lib/errorLib";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import Signin from "./pages/signin/Signin";
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import Home from "./pages/home/Home";
import UserList from "./pages/user/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/user/NewUser";
import VideoList from "./components/videolist/VideoList";
import AddVideo from "./components/videolist/AddVideo";
import VideoSingle from "./components/videosingle/VideoSingle";
import Movies from "./pages/movies/Movies";
import Series from "./pages/series/Series";
import ShortFilms from "./pages/shortFilms/ShortFilms";
import AllVideos from "./pages/allvideos/AllVideos";
import UnAssorted from "./pages/unassorted/UnAssorted";
import SerSea from "./pages/seriesseason/SerSea";
import Settings from "./pages/settings/Settings";
import AddSeries from "./pages/seriesseason/AddSeries";
import TestingPage from "./pages/testingpage/TestingPage";

function App() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);
  
  const onLoad = async() => {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Router>
            <Switch>
              <Route exact path="/signin">
                {/* <Signin /> */}
                {isAuthenticating ? <Redirect to="/" /> : <Signin />}
              </Route>
              <Route exact path="/logout">
                <Signin />
              </Route>
              {/* {isAuthenticating && ( */}
                <>
                  <Topbar />
                  <div className="container">
                    <Sidebar />
                    <Route exact path="/home">
                      <Home />
                    </Route>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route path="/users">
                      <UserList />
                    </Route>
                    <Route path="/user/:userId">
                      <User />
                    </Route>
                    <Route path="/newUser">
                      <NewUser />
                    </Route>
                    <Route path="/settings">
                      <Settings />
                    </Route>
                    <Route path="/videoList">
                      <VideoList />
                    </Route>
                    <Route path="/addVideo">
                      <AddVideo />
                    </Route>
                    <Route path="/videoSingle/:videoId">
                      <VideoSingle />
                    </Route>
                    <Route path="/allvideos">
                      <AllVideos />
                    </Route>
                    <Route path="/movies">
                      <Movies />
                    </Route>
                    <Route path="/series">
                      <Series />
                    </Route>
                    <Route path="/shortfilms">
                      <ShortFilms />
                    </Route>
                    <Route path="/unassorted">
                      <UnAssorted />
                    </Route>
                    <Route path="/sersea">
                      <SerSea />
                    </Route>
                    <Route path="/addseries">
                      <AddSeries />
                    </Route>
                    <Route path="/testingpage">
                      <TestingPage />
                    </Route>
                  </div>
                </>
              {/* )} */}
            </Switch>
        </Router>
      </AppContext.Provider>
    </ThemeProvider>
    )
  );
}

export default App;
