import React, {useState, useEffect} from 'react';
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import VideoList from "../../components/videolist/VideoList"

function ShortFilms() {

  function loadVideoList() {
    // console.log('loadVideoList called with Category = ShortFilm')
    return API.get("clientnamebackend", "/getvideoscategory/ShortFilm");
  }
  
  const [dbData, setDbData] = useState({});
  const { isAuthenticated } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    async function onLoad() {
        if (!isAuthenticated) {
            history.push("/signin");
            return;
        }

        try {
            const videoList = await loadVideoList();
            // console.log('Recvd videoList SHORTFILM = ', videoList)
            setDbData(videoList);
            setIsLoading(false);
        } catch (e) {
            onError(e);
        }

        setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);


  return (
    <VideoList inputData={dbData} inCategory={"ShortFilm"} />
  )
}

export default ShortFilms