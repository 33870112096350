// import * as React from 'react';
import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';

import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import config from "../../config";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={config.client.CMS_CLIENT_WEBSITE}>
      {config.client.CMS_CLIENT_NAME}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {

  const history = useHistory();

  const { userHasAuthenticated } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [rememberMe, setRememberMe] = useState(false);
  const [fields, handleFieldChange] = useFormFields({ email: "", password: "" });

  const validateForm = () => {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  const handleSubmit =  async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   email1: data.get('email'),
    //   password1: data.get('password'),
    // });
    // console.log({
    //   email,
    //   password,
    // });

    setIsLoading(true);


    try {
      await Auth.signIn(fields.email, fields.password);
      // alert("Logged in");
      userHasAuthenticated(true);
      history.push("/home");
    } catch (e) {
      onError(e);
      console.error(e);
      setIsLoading(false);
    }

  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              type="email"
              value={fields.email}
              // onChange={(e) => setEmail(e.target.value)}
              onChange={handleFieldChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={fields.password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={handleFieldChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {/* <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}  disabled={!validateForm()}>
              Sign In
            </Button> */}
            <LoadingButton 
              type="submit" 
              fullWidth 
              variant="contained" 
              sx={{ mt: 3, mb: 2 }}  
              disabled={!validateForm()}
              endIcon={<LoginIcon />}
              loading={isLoading}
              // loadingIndicator="Signin in process..."
              loadingPosition="end"
            >
              Sign In
            </LoadingButton>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2"> Forgot password? </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {`Interested in an account? Mail us - ${config.client.CMS_CLIENT_EMAIL}`}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}