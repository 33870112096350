import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';

import CustomAreaChartNoBshadow from '../chart/CustomAreaChartNoBshadow';

import "./combinedWidget.css";

export default function CombinedWidget({title, totalNumber, currMonthString, chartData, pfxSfx, chartKeyX, chartKeyY}) {
  let curMonthNumber = chartData.length > 0 ? chartData[chartData.length - 1][chartKeyY] : 0;
  let prevMonthNumber = chartData.length > 1 ? chartData[chartData.length - 2][chartKeyY] : 0;
  let momDiff = curMonthNumber - prevMonthNumber;
  pfxSfx = pfxSfx || {pre: '', suf: ''};
  totalNumber = pfxSfx.pre + totalNumber + pfxSfx.suf;
  return (
        <div className="featuredItemData">
          <div className="featuredItemDataTop">
            <div className="featuredItemDataLeft">
              <span className="featuredTitle">{title}</span>
              <div className="featuredCardContainer">
                <span className="featuredCard">{curMonthNumber}</span>
                <span className="featuredCardRate">
                  {(parseFloat(momDiff.toFixed(2)))} 
                  {momDiff > 0 ? <ArrowUpwardOutlinedIcon className="featuredIcon positive"/> : <ArrowDownwardOutlinedIcon className="featuredIcon negative"/>}
                </span>
              </div>
              <span className="featuredSub">Daily diff</span>
            </div>
            <div className="featuredItemDataRight">
              {currMonthString + " " || "Month "} Total: {totalNumber }
            </div>
          </div>
          <div className="featuredItemDataBottom">
            <div className="featuredItemChart">
              <CustomAreaChartNoBshadow data={chartData} dataKeyX={chartKeyX || "name"} dataKeyY={chartKeyY}/>
            </div>
          </div>
        </div>
  );
}
