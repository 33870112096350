import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import "./videoList.css";

export default function VideoList({inputData, inCategory}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dbRefreshToggle, setDbRefreshToggle] = useState(false);
  
  useEffect(() => {
    if (inputData) {
      setData(inputData);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    return () => {
      setIsLoading(true);
      // setDbRefreshToggle(false);
    }
  }, [inputData, dbRefreshToggle]);
  
  const handleDelete = (id) => {
    // setData(data.filter((item) => item.id !== id));
    alert("Please contact admin to de-activate VideoId " + id);
  };

  const refreshPage = () => {
    window.location.reload(false);
  }

  const refreshDatabase = async (e) => {
    e.preventDefault();
    await API.post("clientnamebackend", "/updateMongoWithBrid");
    setDbRefreshToggle(!dbRefreshToggle);
    refreshPage();
  }
  const idWidth = 90;
  const nameWidth = 250;
  const categoryWidth = 100;
  // const ser_seaWidth = 250;
  const seriesWidth = 180;
  const seasonWidth = 70;
  const statusWidth = 120;
  const publishWidth = 120;
  const durationWidth = 100;
  const featuredWidth = 100;
  // const totalViewsWidth = 100;
  // const avgRatingWidth = 100;
  const partnerNameWidth = 100;
  const actionWidth = 150;
  
  const columns = [];

  let idColumn = { field: "id", headerName: "ID", width: idWidth }
  columns.push(idColumn);

  let nameColumn = { 
    field: "videoTitle",
      headerName: "Name",
      width: nameWidth,
      renderCell: (params) => {
        return (
          <div className="videoListItem">
            <img className="videoListImg" src={params.row.videoThumbNail} alt="" />
            {params.row.videoTitle}
          </div>
        );
      },
  }
  columns.push(nameColumn);

  let categoryColumn = { field: "videoCategory", headerName: "Category", width: categoryWidth }
  if (inCategory === 'AllVideos' || inCategory === 'UnAssorted') columns.push(categoryColumn)
  
  let seriesColumn = { 
    field: "videoSeries",
    headerName: "Series",
    width: seriesWidth,
    renderCell: (params) => {
      return (
        <>
          {params.row.videoSeries}
        </>
        )
      }
  }
  if (inCategory !== 'Movie' && inCategory !== 'ShortFilm') columns.push(seriesColumn)

  let seasonColumn = { 
    field: "videoSeason",
    headerName: "Season",
    width: seasonWidth,
    renderCell: (params) => {
      return (
        <>
          {params.row.videoSeason}
        </>
        )
      }
  }
  if (inCategory !== 'Movie' && inCategory !== 'ShortFilm') columns.push(seasonColumn)

  let statusColumn = { 
    field: "videoSrcStatus",
      headerName: "Status @ BridTV",
      width: statusWidth,
      renderCell: (params) => {
        return (
          <>
            {(params.row.videoStatusSrc)? "Active" : "Inactive"}
          </>
        )
      }
  }
  columns.push(statusColumn);
  
  let publishColumn = { 
    field: "videoPublish", 
    headerName: "Publish Date", 
    width: publishWidth, 
    editable: true, 
    type: 'date',
    renderCell: (params) => {
      return (
        <>
          {params.row.videoPublish ? (new Date(params.row.videoPublish)).toISOString().slice(0, 10).replace("T", " ") : ""}
        </>
      )
    }
  }
  columns.push(publishColumn);
  
  let durationColumn = { field: "videoDuration", headerName: "Duration", width: durationWidth }
  columns.push(durationColumn);

  let featuredColumn = { 
    field: "videoFeatured", 
      headerName: "Featured", 
      width: featuredWidth,
      renderCell: (params) => {
        return (
          <>
            {(params.row.videoFeatured)? "Yes" : "No"}
          </>
        )
      }
  }
  columns.push(featuredColumn);
  
  // let totalViewsColumn = { field: "videoTotalViews", headerName: "Total Views", width: totalViewsWidth }
  // columns.push(totalViewsColumn);

  // let avgRatingColumn = { 
  //   field: "avg_rating", 
  //     headerName: "Avg. Rating", 
  //     width: avgRatingWidth,
  //     renderCell: (params) => {
  //       return (
  //         <>
  //           {(params.row.videoTotalRating) || 0 / (params.row.videoTotalRaters || 1)}
  //         </>
  //       )
  //     }
  // }
  // columns.push(avgRatingColumn);
  
  let partnerColumn = { field: "videoPartner", headerName: "Partner", width: partnerNameWidth }
  columns.push(partnerColumn);

  let actionColumn = {
    field: "action",
      headerName: "Action",
      width: actionWidth,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/videoSingle/" + params.row.id}>
              <button className="videoListEdit">Edit</button>
            </Link>
            <DeleteOutlineOutlinedIcon
              className="videoListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
  }
  columns.push(actionColumn);

  return (
    <div className="videoList">
      <div className="videoTitleContainer">
        <span className="videoListHeader"><h2>{inCategory || "Unknown"} List</h2></span>
        <Link to="/addVideo">
          <button className="videoRefButton"> Upload Video </button>
        </Link>
        <button className="videoRefButton" onClick={refreshDatabase} >Refresh DB</button>
      </div>
      <> {data && data.length > 0 && (
        <DataGrid
          rows={data}
          loading={isLoading}
          disableSelectionOnClick
          columns={columns}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pageSize={10}
          components={{ 
            Toolbar: GridToolbar,
            // LoadingOverlay: LinearProgress,
            NoRowsOverlay: () => <div>Loading data...</div>,
          }} 
          // checkboxSelection
        />
        )}
      </>
    </div>
  );
}
