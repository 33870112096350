import React, {useState, useEffect} from 'react';
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import VideoList from "../../components/videolist/VideoList"

function AllVideos() {

  function loadVideoList() {
    // console.log('loadVideoList called with Category = ALL')
    return API.get("clientnamebackend", "/getvideoscategory");
  }
  
  const [dbData, setDbData] = useState({});
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  useEffect(() => {
      // console.log('useEffect called')
      async function onLoad() {
          if (!isAuthenticated) {
              history.push("/signin");
              return;
          }

          try {
              const videoList = await loadVideoList();
              // console.log('Recvd videoList ALL = ', videoList)
              setDbData(videoList);
          } catch (e) {
              onError(e);
          }

          // setIsLoading(false);
      }

      onLoad();
  }, [isAuthenticated]);

  return (
    <VideoList inputData={dbData} inCategory={"AllVideos"} />
  )
}

export default AllVideos