import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Fab from '@mui/material/Fab';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined';
// import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import "./sersea.css";

export default function SerSea() {
  const [data, setData] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      if (!isAuthenticated) {
        history.push("/signin");
        return;
      }
  
      try {
        const series = await loadSeries();
        let seriesDataWithID = series.map((ser) => {
          return {
            ...ser,
            id: ser.seriesId
          };
        });
        // console.log(seriesDataWithID);
        setData(seriesDataWithID);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        onError(e);
      }
        setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);

  
  function loadSeries() {
    return API.get("clientnamebackend", "/getseries");
  }



  const handleSeasonAdd = (id) => {
    setData((prevData, props) => {
      let newData = [...prevData];
      newData.forEach((ser) => {
        if (ser.seriesId === id) {
          let seasonLength = ser.seriesSeasons?.length || 0;
          ser.seriesSeasons.push(`Season ${seasonLength + 1}`);
        }
      });
      return newData;
    });
  };

  const handleSeasonDelete = (id) => {
    setData((prevData, props) => {
      let newData = [...prevData];
      newData.forEach((ser) => {
        if (ser.seriesId === id) {
          let seasonLength = ser.seriesSeasons?.length || 0;
          if (seasonLength > 1) {
            ser.seriesSeasons.pop();
          } else {
            alert("Cannot delete last season");
          }
        }
      });
      return newData;
    });
  };

  async function updateSeries(newSeries) {
    // console.log("call updateSeries with: ", newSeries);
    return API.put("clientnamebackend", "/updateseries/"+newSeries.seriesId, {
      body: {content: newSeries}
    });
  }

  const handleSeriesSave = async (id) => {
    let newData = [...data];
    let seriesToUpdate = newData.find((ser) => {
      return ser.seriesId === id;
    });
    // console.log(seriesToUpdate);
    try {
      setIsLoading(true);
      await updateSeries(seriesToUpdate);
      history.push("/sersea");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
    setIsLoading(false);
  };

  // const handleSeriesDelete = (id) => {
  //   setData(data.filter((item) => item.seriesId !== id));
  // };
  // const handleSeriesCancel = (id) => {
  //   alert("Cancel to be implemented");
  // };
  
  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    {
      field: "seriesTitle",
      headerName: "Series Title",
      editable: true,
      width: 250,
      renderCell: (params) => {
        return (
          <div className="serListUser">
            {/* <img className="serListImg" src={params.row.avatar} alt="" /> */}
            {params.row.seriesTitle}
          </div>
        );
      },
    },
    { field: "seriesDesc", headerName: "Description", width: 500, editable: true  },
    { field: "seriesSeasons", headerName: "Seasons", width: 300},
    { field: "season_action", headerName: "Season", width: 120, 
      renderCell: (params) => {
        return (
          <>
            <Fab size="small" color="success" aria-label="add season">
              <CreateNewFolderOutlinedIcon onClick={() => handleSeasonAdd(params.row.id)} />
            </Fab>
            <Fab size="small" color="warning" aria-label="delete season">
              <FolderDeleteOutlinedIcon onClick={() => handleSeasonDelete(params.row.id)}  />
            </Fab>
          </>
        )
      }
    },
    {
      field: "action",
      headerName: "Series",
      width: 180,
      renderCell: (params) => {
        return (
          <>
            {/* <Fab size="small" color="error" aria-label="delete series">
              <DeleteForeverOutlinedIcon
                onClick={() => handleSeriesDelete(params.row.id)}
                />
            </Fab> */}
            <Fab size="small" color="success" aria-label="save series">
              <SaveOutlinedIcon
                onClick={() => handleSeriesSave(params.row.id)}
                />
            </Fab>
            {/* <Fab size="small" color="warning" aria-label="save series">
              <CancelOutlinedIcon
                onClick={() => handleSeriesCancel(params.row.id)}
                />
            </Fab> */}
          </>
        );
      },
    },
  ];

  return (
    <div className="serList">
      <div className="serTitleContainer">
        <h1 className="serTitle">Series List</h1>
        <Link to="/addseries">
          <button className="serAddButton">Add Series</button>
        </Link>
      </div>
      {data && data.length > 0 && (<DataGrid
        rows={data}
        disableSelectionOnClick
        loading={isLoading}
        columns={columns}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pageSize={10}
      // checkboxSelection
        components={{ 
          Toolbar: GridToolbar,
          NoRowsOverlay: () => <div>Loading data...</div>,
        }}
        onCellEditCommit={(params) => {
          setData((prevData, props) => {
            let newData = [...prevData];
            newData.forEach((ser) => {
              if (ser.seriesId === params.id) {
                ser[params.field] = params.value;
              }
            });
            return newData;
          });
        }}
        // onCellValueChanged={(params) => {
        //   console.log("onCellValueChanged: ", params);
        // }}
        // onCellEditStop={(params) => {
        //   console.log("onCellEditStop: ", params);
        // }}
      />)}
    </div>
  );
}
