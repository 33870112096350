import React, {useState, useEffect} from 'react';
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";

function TestingPage() {

  function loadFromDB() {
    // console.log('testing loadFromDB called in TestingPage')
    return API.get("clientnamebackend", "/channelui/mainpage");
  }
  
  const [dbData, setDbData] = useState({});
  const { isAuthenticated } = useAppContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    async function onLoad() {
        if (!isAuthenticated) {
            history.push("/signin");
            return;
        }

        try {
            const dataFromDB = await loadFromDB();
            // console.log('Recvd dataFromDB UNASSORTED = ', dataFromDB)
            setDbData(dataFromDB);
            setIsLoading(false);
        } catch (e) {
            onError(e);
        }

        setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);


  return (
    <p> {JSON.stringify(dbData)} </p>
  )
}

export default TestingPage