import { Storage } from "aws-amplify";
// import config from "../config";

export async function s3Upload(file, folder="", safeVault=true) {
  let filename = `${Date.now()}-${file.name}`;

  // if specific folder is mentioned, put in that folder, else put in root folder
  // Sample root folder would be 
  // Amazon S3/Buckets/dev2-backend-storagestac-channelsettingsbuckete00-bgh038vjz7f3/private/us-west-1:0ee4b8f3-c2b1-457f-83d4-016f28fac222/

  if (folder) {
    filename = `${folder}/${filename}`;
  }

  // Use vault for secure storage
  let stored;
  if (safeVault) {
    try {
      stored = await Storage.vault.put(filename, file, {
        contentType: file.type,
        // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
        // expires: (new Date().getTime() / 1000) + (60 * 60 * 24 * 7), 
      });
    } catch (e) {
      console.log("Error while uploading file: ", e);
    }
  } else {
    try {
      stored = await Storage.put(filename, file, {
        contentType: file.type,
      });
    } catch (e) {
      console.log("Error while uploading file: ", e);
    }
  }

  return stored.key;
}
