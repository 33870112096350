import config from "../config";
/* eslint @typescript-eslint/no-var-requires: "off" */

const Dropbox = require('dropbox').Dropbox;

function refreshToken() {
  const dbx = new Dropbox({ 
    clientId: config.dropbox.DROPBOX_CLIENT_ID,
    clientSecret: config.dropbox.DROPBOX_CLIENT_SECRET,
    refreshToken: config.dropbox.DROPBOX_REFRESH_TOKEN
  });
  return dbx
}

let dbx = refreshToken();

export async function dbDownload(filePath) {
  // console.log("Inside DB downloadload -> ", filePath);
  
  let downloadloadError = false;
  let downloadUrl;
  try {
    const data = await dbx.filesGetTemporaryLink({ path: filePath });
    // console.log("filesGetTemporaryLink data:::", data);
    downloadUrl = data.result.link;
  } catch(err) {
    downloadloadError = true;
    if (err.status === 401) {
      console.error("File download error DROPBOX-DL-3");
    } else {
      console.error("File download error DROPBOX-GN-3");
    }
  }
  return downloadUrl;
}

export async function dbUpload(file) {
  // console.log("Inside DB upload -> ", file);
  let filename = `${Date.now()}-${file.name}`;
  // console.log({filename})
  
  const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024; // 150MB

  // console.log("DB Folder = ", config.dropbox.DROPBOX_FOLDER);
  let returnPath;
  let uploadError = false;
  if (file.size < UPLOAD_FILE_SIZE_LIMIT) { // File is smaller than 150 Mb - use filesUpload API
    // console.log("File is smaller than 150 Mb - use filesUpload API");
    try {
      const response = await dbx.filesUpload({path: config.dropbox.DROPBOX_FOLDER + filename, contents: file});
      // console.log("dbUpload response --- start");
      // console.log(response);
      // console.log(response.result);
      returnPath = response.result.path_display;
      // console.log("dbUpload response --- stop");
    } catch(err){
      uploadError = true;
      if (err.status === 401) {
        console.error("File download error DROPBOX-UL-1");
      } else {
        console.error("File download error DROPBOX-GN-1");
      }
      console.error(returnPath);
    };
  } else { // File is bigger than 150 Mb - use filesUploadSession* API
    // console.log("File is bigger than 150 Mb - use filesUploadSession API");
    const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

    var workItems = [];     
  
    var offset = 0;

    while (offset < file.size) {
      var chunkSize = Math.min(maxBlob, file.size - offset);
      workItems.push(file.slice(offset, offset + chunkSize));
      offset += chunkSize;
    } 
    // console.log({offset})
    // console.log({workItems})
      
    let acc;
    let sessionId;
    let cursor;
    let finalResp;
    // console.log("Starting session with dbx = ", dbx);
    try{
      for (let idx=0; idx < workItems.length; idx++) {
        if (idx == 0) {
          // console.log("Starting multipart upload of file,idx=", idx);
          const resp = await dbx.filesUploadSessionStart({ close: false, contents: workItems[idx]})
          sessionId = resp.result.session_id;
          // console.log("Done multipart upload of file,idx=", idx);
          // console.log({resp});
        } else if (idx < workItems.length-1) {
          // console.log("Append part to the upload session,idx=", idx);
          cursor = { session_id: sessionId, offset: idx * maxBlob };
          const resp = await dbx.filesUploadSessionAppendV2({ cursor: cursor, close: false, contents: workItems[idx] })
          // console.log("Done multipart upload of file,idx=", idx, resp);
        } else {
          // console.log("Last chunk of data, close session,idx=", idx);
          cursor = { session_id: sessionId, offset: idx * maxBlob };
          const commit = { path: config.dropbox.DROPBOX_FOLDER + filename, mode: 'add', autorename: true, mute: false };
          finalResp = await dbx.filesUploadSessionFinish({ cursor: cursor, commit: commit, contents: workItems[idx] });   
          // console.log({finalResp});
          // console.log("Done multipart upload of file,idx=", idx);
        }
      }
    } catch (err) {
      uploadError = true;
      if (err.status === 401) {
        console.error("File download error DROPBOX-UL-2");
      } else {
        console.error("File download error DROPBOX-GN-2");
      }
    }
    if (!uploadError) {
      returnPath = finalResp.result.path_display;
    }    
  }
  return returnPath;
}
