import CombinedWidget from './CombinedWidget';
import "./featuredInfo.css";

export default function FeaturedInfo({dbData}) {

  let fillRatePrefixSuffix = {pre: '', suf: '%'};
  let revenuePrefixSuffix = {pre: '$', suf: ''};
  return (
    <div className="featured">
      <div className="featuredRow">
        <CombinedWidget title={"Ad Reqs"} currMonthString={dbData.Total.currMonthString} totalNumber={dbData.Total.AdReq} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"AdReq"}/>
        <CombinedWidget title={"Ad Imps"} currMonthString={dbData.Total.currMonthString} totalNumber={dbData.Total.AdImp} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"AdImp"}/>
        <CombinedWidget title={"Ad Errors"} currMonthString={dbData.Total.currMonthString} totalNumber={dbData.Total.AdErr} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"AdErr"}/>
        <CombinedWidget title={"Ad Complete"} currMonthString={dbData.Total.currMonthString} totalNumber={dbData.Total.AdComp} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"AdComp"}/>
      </div>
      <div className="featuredRow">
        <CombinedWidget title={"Fill Rate"} currMonthString={dbData.Total.currMonthString} totalNumber={(parseFloat(dbData.Total.fillRate).toFixed(2))} pfxSfx={fillRatePrefixSuffix} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"fillRate"}/>
        <CombinedWidget title={"Revenue"} currMonthString={dbData.Total.currMonthString} totalNumber={(parseFloat(dbData.Total.AdRevenue).toFixed(2))} pfxSfx={revenuePrefixSuffix} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"AdRevenue"}/>
        <CombinedWidget title={"Videos Played"} currMonthString={dbData.Total.currMonthString} totalNumber={dbData.Total.VidPlays} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"VidPlays"}/>
        <CombinedWidget title={"Plays Completed"} currMonthString={dbData.Total.currMonthString} totalNumber={dbData.Total.VidComps} chartData={dbData.Monthly} chartKeyX={"name"} chartKeyY={"VidComps"}/>
      </div>
    </div>
  );
}
